.home{
    background-color: rgb(192, 192, 213);
    font-family:monospace;
}
.body{
    display:grid;
    grid-template-columns: 1fr 2.5fr 1fr;
}
.left {
    min-height: 90vh;
    background-color: rgb(245, 245, 245);
}
.middle{
    min-height: 90vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);

}
.right {
    min-height: 90vh;
    background-color: rgb(245, 245, 245);
}


/* Media query for small screens */
@media (max-width: 600px) {
    body {
        grid-template-columns: 1fr;
    }

    .left, .right {
        display: none;
    }

    .middle{
        width: 100vw;
    }
}