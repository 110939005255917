.nav {
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    background-color: #333;
    z-index: 1000; 
}

.nav_ul {
    display: flex;
    list-style-type: none; 
    margin: 0;
    padding: 0;
}

.nav_li {
    margin: 0;
}

.nav_a {
    text-decoration: none;
    color: white;
    font-size: 16px;
}

.nav_a:hover {
    color: #2d2d2d; /* Changes color on hover */
    background-color: rgb(221, 221, 221);
}