html{
    width: 100vw;
}

.Article{
    display:flex;
    flex-direction: column;
    max-width:100%;
    min-width: 90%;

    font-family:monospace;
    margin:1rem;
    background-color: rgb(228, 228, 228);
}
.articleTitle{
    font-weight: bold;
    padding:1rem;
    margin:0;



}
.articleDescription{
    padding:1rem;
    padding-top:0;
    margin:0;


}
.articleImage{
    height:175px;
    background-size: cover;
    background-position:center;
}
.articleButtons{
    padding:1rem;
}